
.fpage {
    margin-top: 9vh;
}

.name {
    text-align: center;
    margin-bottom: 0;
}

.retired {
    text-align: center;
    font-style: italic;
}

.fighterPage {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 3fr);
    grid-gap: 10px;
    padding-left: 50px;
    padding-right: 50px;
}

.fighterPage-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.col {
    padding: 3px;
}