.app {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr);
    grid-gap: 10px;
    padding-left: 50px;
    padding-right: 50px;
}

.col {
    padding-left: 3px;
    padding-right: 3px;
}

.line {
    width: 75%;
}

.fightCard {
    position: relative;
    margin: 5px;
    height: 20%;
    border: 1px solid rgb(222, 222, 222);
}

.fighter {
    position: absolute;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 1em;
    text-decoration: none;
    color: black;
}

.one {
    left: 5px;
    text-align: left;
}

.two {
    right: 5px;
    text-align: right;
}

.vs {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
}

.fightDesc {
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.resultCard {
    position: relative;
    margin: 5px;
    height: 20%;
    border: 1px solid rgb(222, 222, 222);
    background: rgb(212,255,217);
    background: linear-gradient(90deg, rgba(212,255,217,1) 0%, rgba(240,240,240,1) 50%, rgba(255,212,212,1) 100%);
}

.weightSel {
    height: 25px;
    margin-left: 5px;
    margin-top: 5px;
}

.table {
    height: 450px;
}