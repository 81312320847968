
#contents {
    margin-top: 11vh;
}

#dayStr {
    position: absolute;
    right: 10px;
    bottom: 50px;
}

#advBtn {
    position: absolute;
    background-color: #f2f2f2;
    color: black;
    width: 200px;
    height: 50px;
    right: 10px;
    bottom: 10px;
}
