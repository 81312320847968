.header {
    position: fixed;
    height: 9vh;
    background: white;
    border-bottom: 1px solid #c4c4c4;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 0px 2px #d1d1d1);
    top: 0;
}

.title {
    font-size: 35px;
    font-style: italic;
    position: absolute;
    text-decoration: none;
    color: black;
    font-weight: bolder;
    left: 2%;
}

.headButton {
    margin-bottom: 1vh;
    margin-top: 2vh;
    padding: 1vh 1vh;
    text-align: center;
    text-decoration: none;
}

.opt {
    position: absolute;
    right: 1%;
}